import React from 'react'
import avocado from '../images/avocado.svg'
import { colors } from '../styles/variables'
import Box from '../styles/Box'
import Wrapper from '../styles/Wrapper'
import { media } from '../styles/minWidth'
import ContactOptions from './ContactOptions'

const AvocadoLine = () => {
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 25,
        position: 'relative',
        [media.medium]: {
          marginBottom: 50,
        },
      }}
    >
      <div
        css={{
          background: 'black',
          height: 2,
          opacity: 0.1,
          position: 'absolute',
          width: '100%',
        }}
      />
      <div
        css={{
          background: colors.orange,
          height: 2,
          position: 'absolute',
          width: 100,
        }}
      />
      <img css={{ position: 'relative' }} src={avocado} alt="Friska" />
    </div>
  )
}

export default () => (
  <Box>
    <Wrapper
      css={{
        marginBottom: 30,
        [media.medium]: {
          marginBottom: 60,
        },
      }}
      contentWidth={840}
    >
      <h2>Ontvang voedingsadvies op maat</h2>
      <p>
       Ben je klaar voor verandering? Maak dan direct een afspraak, zodat je met een persoonlijk voedings- en leefstijladvies aan de slag kunt om jouw doelen te bereiken.
      </p>

      <ContactOptions justify="center" />
    </Wrapper>

    <AvocadoLine />

    <div>&copy; Copyright {new Date().getFullYear()} - Friska </div>
  </Box>
)
