import React from 'react'
import { media } from './minWidth'

export default ({ children }) => (
  <div
    css={{
      marginBottom: 40,
      [media.medium]: { marginBottom: 60 },
      [media.navigation]: { marginBottom: 100 },
    }}
  >
    {children}
  </div>
)
