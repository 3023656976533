import React from 'react'
import Button from './Button'
import Telephone from './Telephone'

const buttonBreakpoint = '@media(min-width: 420px)'

export default ({
  label = 'Neem contact op',
  link = '/contact',
  justify = 'flex-start',
}) => (
  <div
    css={{
      textAlign: 'center',
      [buttonBreakpoint]: {
        display: 'flex',
        justifyContent: justify,
      },
    }}
  >
    <Button
      styles={{
        margin: '0 auto 25px',
        maxWidth: 280,
        [buttonBreakpoint]: {
          margin: '0 30px 0 0',
        },
      }}
      to={link}
    >
      {label}
    </Button>
    <Telephone styles={{ justifyContent: 'center' }} />
  </div>
)
