const breakpoints = {
  medium: 640,
  navigation: 800,
}

export const media = Object.keys(breakpoints).reduce((media, name) => {
  media[name] = `@media(min-width: ${breakpoints[name]}px)`

  return media
}, {})
