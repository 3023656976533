import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Section from '../styles/Section'
import { Global, css } from '@emotion/react'
import { colors } from '../styles/variables'
import { media } from '../styles/minWidth'

export default ({ title, content, children }) => (
  <div>
    <Global styles={css`
      html {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
      }

      *::before,
      *::after,
      * {
        box-sizing: inherit;
      }

      body {
        color: ${colors.grey.medium};
        font-family: 'Libre Franklin', sans-serif;
        font-size: 0.9375em;
        margin: 0;

        ${media.medium} {
          font-size: 1em;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${colors.brown};
        font-family: 'Quicksand', sans-serif;
        font-weight: 800;
        line-height: 1.5em;
        margin: 0 0 1em;
      }

      h1 {
        font-size: 2.25em;
        ${media.medium} {
          font-size: 3em;
        }
      }

      h2 {
        font-size: 2em;
        ${media.medium} {
          font-size: 2.25em;
        }
      }

      h3 {
        font-size: 1.5em;
        ${media.medium} {
          font-size: 1.625em;
        }
      }

      h3,
      h4,
      h5,
      h6 {
        font-weight: 500;
      }

      h4,
      h5,
      h6 {
        font-size: 1.125em;
      }

      p,
      ul,
      ol {
        line-height: 2em;
        margin: 0 0 2em;
      }

      ul,
      ol {
        list-style-position: outside;
        padding: 0 0 0 20px;
      }

      a {
        color: ${colors.primary};

        &:hover {
          color: ${colors.hover};
        }
      }

      table {
        border-collapse: collapse;
        margin: 0 0 2em;
        width: 100%;

        tr {
          border-bottom: 1px solid ${colors.grey.extraLight};
          vertical-align: top;
        }

        thead tr {
          border-width: 2px;
        }

        tbody tr:last-child {
          border: 0;
        }

        tfoot tr {
          border: 0;
          border-top: 2px solid ${colors.grey.extraLight};
        }

        th {
          text-align: left;
        }

        th,
        td {
          padding: 0.5em;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    `
    } />
    <Helmet
      link={[
        {
          href:
            'https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,700|Quicksand:400,500,700',
          rel: 'stylesheet',
        },
      ]}
      title={`${title || 'Voedingsadvies op maat'} - Friska diëtist`}
      meta={[
        {
          name: 'description',
          content:
            content ||
            'Friska is een kleinschalige diëtistenpraktijk in Boekelo. Wij specialiseren ons in persoonlijk advies op maat over voeding en leefstijl.',
        },
      ]}
    />
    <Section>{children}</Section>
    <Footer />
  </div>
)
