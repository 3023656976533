import React from 'react'
import { media } from './minWidth'
import { colors } from './variables'

export default ({ children }) => (
  <div
    css={{
      background: colors.orange,
      padding: '30px 0',
      textAlign: 'center',
      [media.medium]: {
        padding: '60px 0',
      },
    }}
  >
    {children}
  </div>
)
