export const colors = {
  brown: 'rgb(50, 40, 30)',
  grey: {
    extraLight: 'rgb(240, 240, 240)',
    light: 'rgb(160, 160, 160)',
    medium: 'rgb(80, 80, 80)',
  },
  primary: 'rgb(245, 30, 80)',
  hover: 'rgb(185, 0, 20)',
  orange: 'rgb(255, 248, 242)',
}

export const distances = {
  gridMargin: {
    small: 20,
    large: 30,
  },
}

export const breakpoints = {
  fullGrid: '@media(min-width: 1100px)',
}
