import styled from '@emotion/styled'
import { media } from './minWidth'

const padding = 40

export default styled.div`
  margin: 0 auto;
  max-width: ${p => (p.contentWidth || 1200) + padding * 2 + 'px'};
  padding: 0 20px;
  width: 100%;

  ${media.medium} {
    padding: 0 ${padding}px;
  }
`
