import React from 'react'
import phone from '../images/phone.svg'

export default props => {
  return (
    <span
      css={[
        props.styles,
        {
          alignItems: 'center',
          display: 'flex',
          fontWeight: 800,
        },
      ]}
    >
      <img
        css={{ marginRight: 10 }}
        src={phone}
        alt="Bel me via +31 06 25 46 86 93"
      />
      06 25 46 86 93
    </span>
  )
}
